var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('section',{staticClass:"mb-12 text-center"},[_c('h1',{staticClass:"mb-2 text-h3"},[_vm._v("Orders")]),_c('div',{staticClass:"body-2 font-weight-light"},[_vm._v("Detail of Orders")])]),_c('v-card',_vm._b({staticClass:"v-card--material mt-4 px-4",attrs:{"color":"white"}},'v-card',_vm.$attrs,false),[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.GetOrders,"search":_vm.search},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.code == 200 ? 'green lighten-1' : 'red lighten-1',"dark":""}},[_vm._v(_vm._s(item.code == 200 ? "mdi-check-circle-outline" : "mdi-close-circle-outline"))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" ₹ "+_vm._s(item.amount)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.viewOrder(item.id)}}},[_vm._v(" mdi-eye ")])]}}],null,true)})],1),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition"},model:{value:(_vm.ordersDialog),callback:function ($$v) {_vm.ordersDialog=$$v},expression:"ordersDialog"}},[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers2,"items":_vm.GetOrder,"search":_vm.search2},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"tile":""}},[_c('img',{attrs:{"src":(_vm.kUrl + "/" + (item.image))}})])]}}],null,true)}),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.ordersDialog = false}}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }