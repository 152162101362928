<template>
  <v-container fluid>
    <section class="mb-12 text-center">
      <h1 class="mb-2 text-h3">Dealership</h1>
    </section>
    <v-subheader class="pl-0 mt-12">Update Dealership Price </v-subheader>
    <v-card class="mx-auto pa-8" color="white" width="100%">
      <v-row>
        <v-text-field
          label="Dealership Price"
          outlined
          v-model="dealershipPrice"
          dense
          hide-details=""
        ></v-text-field>
        <v-btn
          class="mx-2"
          color="yellow darken-2"
          :disabled="!dealershipPrice"
          :loading="currentDealershipPriceLoader"
          @click="handleDealershipPriceUpdate()"
          >Update</v-btn
        >
      </v-row>
    </v-card>
    <v-card v-bind="$attrs" class="v-card--material mt-4 px-4" color="white">
      <div v-if="!GetAllDealership.length" class="text-center py-4">
        No Data
      </div>

      <!-- List of testimonials -->
      <v-row v-else dense class="py-4">
        <v-col
          v-for="(dealership, key) in GetAllDealership"
          :key="dealership.id"
          cols="12"
          sm="6"
          md="3"
        >
          <v-card>
            <v-card-text>
              <p>Name: {{ dealership.name }}</p>
              <p>Email: {{ dealership.email }}</p>
              <p>Mobile: {{ dealership.mobile }}</p>
              <p>Gender: {{ dealership.gender }}</p>
              <p>Tehsil: {{ dealership.tehsil }}</p>
              <p>District: {{ dealership.district }}</p>
              <p>State: {{ dealership.state }}</p>
              <p>PinCode: {{ dealership.pin }}</p>
              <p>Payment Status: {{ dealership.payment_status }}</p>
            </v-card-text>

            <v-card-actions>
              <v-btn color="secondary" text @click="editButton(key)">
                Edit
              </v-btn>
              <v-divider></v-divider>

              <v-btn color="error" text @click="deleteButton(dealership.id)">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <!--  Dialog box  -->
    <v-row justify="center">
      <v-dialog v-model="editDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Dealership</span>
          </v-card-title>

          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    label="Name *"
                    required
                    :rules="[(v) => !!v || 'Name is required']"
                    v-model="dealership.name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Email *"
                    required
                    :rules="[(v) => !!v || 'Email is required']"
                    v-model="dealership.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Mobile *"
                    required
                    :rules="[(v) => !!v || 'Mobile is required']"
                    v-model="dealership.mobile"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Gender *"
                    required
                    :rules="[(v) => !!v || 'Gender is required']"
                    v-model="dealership.gender"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Tehsil *"
                    required
                    :rules="[(v) => !!v || 'Tehsil is required']"
                    v-model="dealership.tehsil"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="District *"
                    required
                    :rules="[(v) => !!v || 'District is required']"
                    v-model="dealership.district"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="State *"
                    required
                    :rules="[(v) => !!v || 'State is required']"
                    v-model="dealership.state"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Pin *"
                    required
                    :rules="[(v) => !!v || 'Pin is required']"
                    v-model="dealership.pin"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Payment Status *"
                    required
                    :rules="[(v) => !!v || 'Payment Status is required']"
                    v-model="dealership.payment_status"
                  ></v-text-field>
                </v-col> </v-row
            ></v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="editDialog = false">
              Close
            </v-btn>
            <v-btn color="secondary" text @click="submitDealershipData()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Delete confirmation -->
    <v-row justify="center">
      <v-dialog
        v-model="deleteConfirmDialog"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card>
          <v-toolbar color="warning" dark>Confirmation</v-toolbar>
          <v-card-text>
            <div class="headline pt-12">Do you want to remove this Dealer</div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="deleteConfirmDialog = false">No</v-btn>
            <v-btn text @click="confirmDelete()">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    editDialog: false,
    deleteConfirmDialog: false,
    currentDealershipPriceLoader: false,
    valid: true,
    currentDealerId: "",
    dealershipPrice: null,
    dealershipName: null,
    dealership: {
      id: "",
      name: "",
      email: "",
      mobile: "",
      gender: "",
      tehsil: "",
      district: "",
      state: "",
      pin: "",
      payment_status: "",
    },
  }),
  methods: {
    ...mapActions([
      "fetchAllDealership",
      "updateDealership",
      "deleteDealer",
      "getCurrentDealershipPrice",
      "updateCurrentDealershipPrice",
    ]),
    editButton(key) {
      this.editDialog = true;
      this.dealership.name = this.GetAllDealership[key].name;
      this.dealership.email = this.GetAllDealership[key].email;
      this.dealership.mobile = this.GetAllDealership[key].mobile;
      this.dealership.gender = this.GetAllDealership[key].gender;
      this.dealership.tehsil = this.GetAllDealership[key].tehsil;
      this.dealership.district = this.GetAllDealership[key].district;
      this.dealership.state = this.GetAllDealership[key].state;
      this.dealership.pin = this.GetAllDealership[key].pin;
      this.dealership.payment_status =
        this.GetAllDealership[key].payment_status;
      this.dealership.id = this.GetAllDealership[key].id;
    },

    async submitDealershipData() {
      if (this.$refs.form.validate()) {
        this.editDialog = false;
        this.$vloading.show();
        try {
          await this.updateDealership(this.dealership);

          this.$toasted.show("Successfully Updated", {
            type: "success",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        } catch (error) {
          this.$toasted.show(error, {
            type: "error",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        }

        this.$vloading.hide();
      }
    },

    deleteButton(id) {
      this.deleteConfirmDialog = true;
      this.currentDealerId = id;
    },
    async confirmDelete() {
      this.deleteConfirmDialog = false;
      this.$vloading.show();
      try {
        await this.deleteDealer(this.currentDealerId);
        this.$toasted.show("Successfully removed", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
      this.$vloading.hide();
    },

    async handleDealershipPriceUpdate() {
      this.currentDealershipPriceLoader = true;
      try {
        var response = await this.updateCurrentDealershipPrice({
          name: this.dealershipName,
          price: this.dealershipPrice,
        });
        this.dealershipPrice = response["value"];
        this.dealershipName = response["name"];
        this.$toasted.show("Successfully updated", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
      this.currentDealershipPriceLoader = false;
    },
  },
  computed: {
    ...mapGetters(["GetAllDealership"]),
  },
  async created() {
    this.$vloading.show();
    try {
      await this.fetchAllDealership();
      await this.getCurrentDealershipPrice().then((res) => {
        this.dealershipPrice = res["value"];
        this.dealershipName = res["name"];
      });
    } catch (error) {
      this.$toasted.show(error, {
        type: "error",
        duration: 5000,
        position: "top-center",
        theme: "bubble",
        icon: "mdi-clipboard-check",
        iconPack: "mdi",
      });
    }
    this.$vloading.hide();
  },
};
</script>

