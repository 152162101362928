<template>
  <v-container fluid>
    <section class="mb-12 text-center">
      <h1 class="mb-2 text-h3">Blogs</h1>

      <div class="body-2 font-weight-light">
        Click on + button to add a new blog
      </div>
    </section>
    <!--  white background  -->
    <v-card v-bind="$attrs" class="v-card--material mt-4 px-4" color="white">
      <v-card-title class="align-start">
        <!-- Plus button -->
        <v-btn
          @click="addBlogsDialog = !addBlogsDialog"
          color="primary"
          class="overflow-hidden mt-n9 transition-swing v-card--material__sheet"
          elevation="3"
          fab
          x-large
          tile
          ><v-icon large>mdi-plus</v-icon></v-btn
        >
      </v-card-title>
      <!-- Item inside white background -->
      <div v-if="!GetBlogs.length" class="text-center py-4">No Blogs</div>
      <v-row v-else dense class="py-4">
        <v-col v-for="(blog, key) in GetBlogs" :key="blog.id" cols="12" sm="4">
          <!-- Blogs card  -->
          <v-card>
            <v-img
              contain
              @click="showDialogBlog(key)"
              :src="`${kUrl}/${blog.image}`"
              :lazy-src="`${kUrl}/${blog.image}`"
              height="200px"
              style="cursor: pointer"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <v-card-title class="headline">
              {{ blog.title }}/ {{ blog.h_title }}
            </v-card-title>

            <v-card-subtitle class="pb-0 secondary--text"
              >Created: {{ blog.created }}
            </v-card-subtitle>
            <v-card-subtitle class="py-0 primary--text"
              >Likes: {{ blog.like_count }}
            </v-card-subtitle>

            <v-card-actions>
              <v-btn color="secondary" text @click="editButton(key)">
                Edit
              </v-btn>
              <v-divider></v-divider>

              <v-btn color="error" text @click="deleteButton(blog.id)">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <!-- Add Blog Dialog box  -->
    <v-row justify="center">
      <v-dialog v-model="addBlogsDialog" persistent max-width="600px">
        <v-card>
          <v-img
            v-if="isEdit"
            :src="`${kUrl}/${oldimage}`"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          />
          <v-card-title>
            <span class="headline">{{ isEdit ? "Edit" : "Add" }} Blogs</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row dense>
                <v-col cols="12">
                  <v-file-input
                    v-model="blog.image"
                    filled
                    placeholder="Pick an Image"
                    prepend-icon="mdi-camera"
                    label="Add Image"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Title *"
                    required
                    :rules="[(v) => !!v || 'Title is required']"
                    v-model="blog.title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Title in Hindi *"
                    required
                    :rules="[(v) => !!v || 'Hindi Title is required']"
                    v-model="blog.h_title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <!-- class="editor"
                    ref="myTextEditor" -->
                  <quill-editor
                    v-model="blog.description"
                    :options="editorOption"
                  />

                  <!-- <v-textarea
                    label="Blog Content *"
                    required
                    :rules="[(v) => !!v || 'Content is required']"
                    v-model="blog.description"
                  >
                  </v-textarea> -->
                </v-col>
                <v-col cols="12">
                  <quill-editor
                    v-model="blog.h_description"
                    :options="editorOption"
                  />
                  <!-- <v-textarea
                    label="Content in Hindi *"
                    required
                    :rules="[(v) => !!v || 'Content in Hindi is required']"
                    v-model="blog.h_description"
                  > 
                  </v-textarea>-->
                </v-col>
              </v-row>
            </v-form>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeButton()"> Close </v-btn>
            <v-btn color="secondary" text @click="submitBlogData()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Show Individual blogs  -->
    <v-dialog
      v-if="blogKey != null"
      v-model="showBlog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="showBlog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Blog Detail</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-subheader class="pl-0">Image</v-subheader>
          <v-img
            class="mx-auto"
            :src="`${kUrl}/${GetBlogs[blogKey].image}`"
            :lazy-src="`${kUrl}/${GetBlogs[blogKey].image}`"
            height="400px"
            width="50%"
            style="cursor: pointer"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-divider></v-divider>
          <v-subheader class="pl-0">Details</v-subheader>
          <v-row dense>
            <v-col cols="12" sm="4"
              ><p class="headline">
                {{ GetBlogs[blogKey].title }}/ {{ GetBlogs[blogKey].h_title }}
              </p></v-col
            >
            <v-col cols="12" sm="4"
              ><p class="headline">
                Created: {{ GetBlogs[blogKey].created }}
              </p></v-col
            >
            <v-col cols="12" sm="4"
              ><p class="headline">
                Likes: {{ GetBlogs[blogKey].like_count }}
              </p></v-col
            >
          </v-row>
          <v-subheader class="pl-0">Description</v-subheader>
          <p class="mt-2 headline">{{ GetBlogs[blogKey].description }}</p>
          <v-subheader class="pl-0">Description in Hindi</v-subheader>
          <p class="mt-2 headline">{{ GetBlogs[blogKey].h_description }}</p>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- Delete confirmation -->
    <v-dialog
      v-model="deleteConfirmDialog"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-toolbar color="warning" dark>Confirmation</v-toolbar>
        <v-card-text>
          <div class="headline pt-12">Do you want to remove this blog</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="deleteConfirmDialog = false">No</v-btn>
          <v-btn text @click="confirmDelete()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import { imageURL } from "@/assets/js/constant";
import { quillEditor } from "vue-quill-editor";
import { debounce } from "@/assets/js/debounce";
// import theme style
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    quillEditor,
  },
  data: () => ({
    kUrl: imageURL,
    deleteConfirmDialog: false,
    isEdit: false,
    blogId: "",
    blogKey: null,
    showBlog: false,
    addBlogsDialog: false,
    valid: true,
    blog: {
      title: "",
      h_title: "",
      description: "Blog Content *",
      h_description: "Blog Content in Hindi*",
      image: null,
    },
    oldimage: "",
    likeCount: 0,
    editorOption: {
      modules: {
        toolbar: [
          ["bold", "italic", "underline"],
          ["blockquote"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          ["link"],
        ],
        syntax: {
          highlight: (text) => hljs.highlightAuto(text).value,
        },
      },
    },
  }),
  methods: {
    ...mapActions(["fetchAllBlogs", "addBlog", "deleteBlog", "updateBlog"]),
    showDialogBlog(key) {
      this.blogKey = key;
      this.showBlog = true;
    },

    async submitBlogData() {
      if (this.$refs.form.validate()) {
        this.addBlogsDialog = false;
        this.$vloading.show();
        try {
          if (this.isEdit) {
            this.blog.oldimage = this.oldimage;
            this.blog.id = this.blogId;
            this.blog.likeCount = this.likeCount;

            await this.updateBlog(this.blog);
          } else {
            await this.addBlog(this.blog);
          }
          this.$toasted.show("Successfully Done", {
            type: "success",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        } catch (error) {
          this.$toasted.show(error, {
            type: "error",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        }

        this.$vloading.hide();
      }
    },
    deleteButton(id) {
      this.blogId = id;
      this.deleteConfirmDialog = true;
    },
    closeButton() {
      this.addBlogsDialog = false;
      this.blog.title = "";
      this.blog.h_title = "";
      this.blog.description = "";
      this.blog.h_description = "";
      this.blog.image = null;
    },
    async confirmDelete() {
      this.deleteConfirmDialog = false;
      this.$vloading.show();
      try {
        await this.deleteBlog(this.blogId);
        this.$toasted.show("Successfully removed", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
      this.$vloading.hide();
    },

    editButton(key) {
      this.addBlogsDialog = true;
      this.isEdit = true;
      this.blog.title = this.GetBlogs[key].title;
      this.blog.h_title = this.GetBlogs[key].h_title;
      this.blog.description = this.GetBlogs[key].description;
      this.blog.h_description = this.GetBlogs[key].h_description;
      this.oldimage = this.GetBlogs[key].image;
      this.blogId = this.GetBlogs[key].id;
      this.likeCount = this.GetBlogs[key].like_count;
    },
  },
  computed: {
    ...mapGetters(["GetBlogs"]),
  },
  async created() {
    this.$vloading.show();
    try {
      await this.fetchAllBlogs();
    } catch (error) {
      this.$toasted.show(error, {
        type: "error",
        duration: 5000,
        position: "top-center",
        theme: "bubble",
        icon: "mdi-clipboard-check",
        iconPack: "mdi",
      });
    }
    this.$vloading.hide();
  },
};
</script>


<style>
</style>