<template>
  <v-container fluid>
    <section class="mb-12 text-center">
      <h1 class="mb-2 text-h3">Products</h1>

      <div class="body-2 font-weight-light">
        Click on + button to add a new product
      </div>
    </section>
    <v-card v-bind="$attrs" class="v-card--material mt-4 px-4" color="white">
      <v-card-title class="align-start">
        <v-btn
          @click="addProductDialog = !addProductDialog"
          color="primary"
          class="overflow-hidden mt-n9 transition-swing v-card--material__sheet"
          elevation="3"
          fab
          x-large
          tile
          ><v-icon large>mdi-plus</v-icon></v-btn
        >
      </v-card-title>
      <v-row dense class="py-4">
        <h3 v-if="CatProducts.length == 0" class="mx-auto">No products</h3>
        <v-col
          v-else
          v-for="(product, key) in CatProducts"
          :key="product.id"
          cols="12"
          sm="6"
          md="3"
        >
          <v-card>
            <v-img
             @click="
                $router.push({
                  name: 'Product',
                  params: { productId: product.id },
                })
              "
              style="cursor: pointer"
              :src="`${kUrl}/${product.image}`"
              :lazy-src="`${kUrl}/${product.image}`"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <!-- Image loader -->
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
              <v-card-title class="py-0 headline"
                >{{ product.title }}/ {{ product.h_title }}</v-card-title
              >
              <v-card-title class="headline"
                >{{ product.sub_title }}/
                {{ product.h_sub_title }}</v-card-title
              >
            </v-img>
            <v-card-title class="pt-2 pb-0"
              >Price: ₹ {{ product.price }}</v-card-title
            ><v-spacer></v-spacer>
            <v-card-title class="py-0"
              >Quantity: {{ product.quantity }}</v-card-title
            >
            <div class="text-center">
              <v-chip
                pill
                x-small
                v-if="product.is_enable == '1'"
                class="py-0 ml-1"
                color="primary"
                text-color="white"
              >
                Enable
                <v-icon x-small right> mdi-star </v-icon>
              </v-chip>
            </div>
            <v-card-actions>
              <v-btn color="secondary" text @click="editButton(key)">
                Edit
              </v-btn>
              <v-divider></v-divider>
              <v-btn color="error" text @click="deleteButton(product.id)">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <!--  Dialog box  -->
    <v-row justify="center">
      <v-dialog v-model="addProductDialog" persistent max-width="600px">
        <v-card>
          <v-img
            contain
            v-if="isEdit"
            :src="`${kUrl}/${oldprodimage}`"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          />
          <v-card-title>
            <span class="headline">{{ isEdit ? "Edit" : "Add" }} product</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row dense>
                <v-col cols="12">
                  <v-file-input
                    filled
                    v-model="product.prodimage"
                    accept="image/png, image/jpeg, image/gif, image/jpg"
                    placeholder="Pick an Image"
                    prepend-icon="mdi-camera"
                    label="Add Image"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Title *"
                    required
                    :rules="[(v) => !!v || 'Title is required']"
                    v-model="product.title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Title in Hindi *"
                    required
                    :rules="[(v) => !!v || 'Hindi Title is required']"
                    v-model="product.h_title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Subtitle *"
                    required
                    :rules="[(v) => !!v || 'Subtitle is required']"
                    v-model="product.subtitle"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Subtitle in Hindi *"
                    required
                    :rules="[(v) => !!v || 'Hindi Subtitle is required']"
                    v-model="product.h_subtitle"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Price *"
                    prefix="₹"
                    required
                    :rules="[(v) => !!v || 'Price is required']"
                    v-model="product.price"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Quantity *"
                    required
                    :rules="[(v) => !!v || 'Quantity is required']"
                    v-model="product.quantity"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="isEdit">
                  <v-radio-group row v-model="enable">
                    <v-radio label="Not Enable" :value="0"></v-radio>
                    <v-radio label="Enable" :value="1"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-form>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeButton()"> Close </v-btn>
            <v-btn color="secondary" text @click="submitSliderData()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    
    <!-- Delete confirmation -->
    <v-row justify="center">
      <v-dialog
        v-model="deleteConfirmDialog"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card>
          <v-toolbar color="warning" dark>Confirmation</v-toolbar>
          <v-card-text>
            <div class="headline pt-12">
              Do you want to remove this Products
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="deleteConfirmDialog = false">No</v-btn>
            <v-btn text @click="confirmDelete()">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { imageURL } from "@/assets/js/constant";
export default {
  data: () => ({
    kUrl: imageURL,
    deleteConfirmDialog: false,
    addProductDialog: false,
    valid: true,
    isEdit: false,
    oldprodimage: "",
    productId: "",
    product: {
      title: "",
      h_title: "",
      subtitle: "",
      h_subtitle: "",
      price: "",
      quantity: "",
      prodimage: null,
    },
    enable: 0,
  }),
  props: {
    catId: {
      type: String,
    },
  },
  methods: {
    ...mapActions([
      "fetchProductsByCatId",
      "addProduct",
      "deleteProduct",
      "updateProduct",
    ]),
    async submitSliderData() {
      if (this.$refs.form.validate()) {
        this.addProductDialog = false;
        this.$vloading.show();
        try {
          if (this.isEdit) {
            this.product.cat_id = this.catId;
            this.product.productId = this.productId;
            this.product.oldprodimage = this.oldprodimage;
            this.product.enable = this.enable;
            await this.updateProduct(this.product);
          } else {
            this.product.cat_id = this.catId;
            await this.addProduct(this.product);
          }
          this.$toasted.show("Successfully Done", {
            type: "success",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        } catch (error) {
          this.$toasted.show(error, {
            type: "error",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        }

        this.$vloading.hide();
      }
    },
    editButton(key) {
      this.isEdit = true;
      this.addProductDialog = true;
      this.productId = this.CatProducts[key].id;
      this.product.title = this.CatProducts[key].title;
      this.product.h_title = this.CatProducts[key].h_title;
      this.product.subtitle = this.CatProducts[key].sub_title;
      this.product.h_subtitle = this.CatProducts[key].h_sub_title;
      this.product.price = this.CatProducts[key].price;
      this.product.quantity = this.CatProducts[key].quantity;
      this.oldprodimage = this.CatProducts[key].image;
      this.enable = parseInt(this.CatProducts[key].is_enable);
    },
    closeButton() {
      this.isEdit = false;
      this.addProductDialog = false;
      this.product.title = "";
      this.product.h_title = "";
      this.product.subtitle = "";
      this.product.h_subtitle = "";
      this.product.quantity = "";
      this.product.price = "";
      this.product.prodimage = null;
    },
    deleteButton(id) {
      this.deleteConfirmDialog = true;
      this.productId = id;
    },

    async confirmDelete() {
      this.deleteConfirmDialog = false;
      this.$vloading.show();
      try {
        await this.deleteProduct(this.productId);
        this.$toasted.show("Successfully removed", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
      this.$vloading.hide();
    },
  },
  computed: {
    ...mapGetters(["CatProducts"]),
  },
  async created() {
    this.$vloading.show();
    try {
      await this.fetchProductsByCatId(this.catId);
    } catch (error) {
      this.$toasted.show(error, {
        type: "error",
        duration: 5000,
        position: "top-center",
        theme: "bubble",
        icon: "mdi-clipboard-check",
        iconPack: "mdi",
      });
    }
    this.$vloading.hide();
  },
};
</script>

