<template>
  <v-container fluid>
    <section class="mb-12 text-center">
      <h1 class="mb-2 text-h3">Home Sliders</h1>

      <div class="body-2 font-weight-light">
        Click on + button to add a new Slider
      </div>
    </section>

    <v-card>
      <v-tabs color="primary" right>
        <v-tab>Mobile App</v-tab>
        <v-tab>Website</v-tab>

        <!-- Mobile app Sliders -->
        <v-tab-item>
          <!-- Main card  -->
          <v-card
            v-bind="$attrs"
            elevation="0"
            class="v-card--material mt-4 px-4"
            color="white"
          >
            <v-card-title class="align-start">
              <v-btn
                @click="addMobileSliderDialog = !addMobileSliderDialog"
                color="primary"
                class="
                  overflow-hidden
                  mt-n9
                  transition-swing
                  v-card--material__sheet
                "
                elevation="3"
                fab
                x-large
                tile
                ><v-icon large>mdi-plus</v-icon></v-btn
              >
            </v-card-title>
            <div v-if="!getAppSliders.length" class="text-center py-4">
              No Slider
            </div>
            <v-row v-else dense class="py-4">
              <!-- List of slider  -->
              <v-col
                v-for="(slider, key) in getAppSliders"
                :key="slider.id"
                cols="12"
                sm="6"
              >
                <v-card>
                  <v-img
                    contain
                    :src="`${kUrl}/${slider.image}`"
                    :lazy-src="`${kUrl}/${slider.image}`"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    height="200px"
                  >
                    <!-- Image loading  -->
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                    <v-card-title class="headline"
                      >{{ slider.title }}/ {{ slider.h_title }}</v-card-title
                    >
                  </v-img>
                  <div class="text-center">
                    <v-chip
                      pill
                      small
                      v-if="slider.is_enable == '1'"
                      class="mt-1"
                      color="secondary"
                      text-color="white"
                    >
                      Enable
                      <v-icon x-small right> mdi-star </v-icon>
                    </v-chip>
                  </div>
                  <!-- Edit and delete button  -->
                  <v-card-actions>
                    <v-btn
                      color="secondary"
                      text
                      @click="editMobileButton(key)"
                    >
                      Edit
                    </v-btn>
                    <v-divider></v-divider>

                    <v-btn
                      color="error"
                      text
                      @click="appDeleteButton(slider.id)"
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <!-- Website Sliders -->
        <v-tab-item>
          <!-- Main card  -->
          <v-card
            v-bind="$attrs"
            elevation="0"
            class="v-card--material mt-4 px-4"
            color="white"
          >
            <v-card-title class="align-start">
              <v-btn
                @click="addWebSliderDialog = !addWebSliderDialog"
                color="primary"
                class="
                  overflow-hidden
                  mt-n9
                  transition-swing
                  v-card--material__sheet
                "
                elevation="3"
                fab
                x-large
                tile
                ><v-icon large>mdi-plus</v-icon></v-btn
              >
            </v-card-title>
            <div v-if="!getWebSliders.length" class="text-center py-4">
              No Slider
            </div>
            <v-row v-else dense class="py-4">
              <!-- List of slider  -->
              <v-col
                v-for="(slider, key) in getWebSliders"
                :key="slider.id"
                cols="12"
                sm="6"
              >
                <v-card>
                  <v-img
                    contain
                    :src="`${kUrl}/${slider.image}`"
                    :lazy-src="`${kUrl}/${slider.image}`"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    height="200px"
                  >
                    <!-- Image loading  -->
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                    <v-card-title class="headline"
                      >{{ slider.title }}/ {{ slider.h_title }}</v-card-title
                    >
                  </v-img>
                  <div class="text-center">
                    <v-chip
                      pill
                      small
                      v-if="slider.is_enable == '1'"
                      class="mt-1"
                      color="secondary"
                      text-color="white"
                    >
                      Enable
                      <v-icon x-small right> mdi-star </v-icon>
                    </v-chip>
                  </div>
                  <!-- Edit and delete button  -->
                  <v-card-actions>
                    <v-btn color="secondary" text @click="editWebButton(key)">
                      Edit
                    </v-btn>
                    <v-divider></v-divider>

                    <v-btn
                      color="error"
                      text
                      @click="webDeleteButton(slider.id)"
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <!--  Mobile app Dialog box  -->
    <v-row justify="center">
      <v-dialog v-model="addMobileSliderDialog" persistent max-width="600px">
        <v-card>
          <v-img
            contain
            v-if="isAppEdit"
            :src="`${kUrl}/${mobileOldimage}`"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          />
          <v-card-title>
            <span class="headline"
              >{{ isAppEdit ? "Edit" : "Add" }} Mobile App Slider</span
            >
          </v-card-title>
          <v-card-text>
            <v-form ref="form1" v-model="valid" lazy-validation>
              <v-row dense>
                <v-col cols="12">
                  <v-file-input
                    v-model="mobileSlider.image"
                    filled
                    placeholder="Pick an Image"
                    prepend-icon="mdi-camera"
                    label="Add Image"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Title *"
                    required
                    :rules="[(v) => !!v || 'Title is required']"
                    v-model="mobileSlider.title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Title in Hindi *"
                    required
                    :rules="[(v) => !!v || 'Hindi Title is required']"
                    v-model="mobileSlider.h_title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-radio-group row v-model="mobileSlider.enable">
                    <v-radio label="Not Enable" :value="0"></v-radio>
                    <v-radio label="Enable" :value="1"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-form>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeButton()"> Close </v-btn>
            <v-btn color="secondary" text @click="submitMobileSliderData()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!--  Website Dialog box  -->
    <v-row justify="center">
      <v-dialog v-model="addWebSliderDialog" persistent max-width="600px">
        <v-card>
          <v-img
            contain
            v-if="isWebEdit"
            :src="`${kUrl}/${webOldimage}`"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          />
          <v-card-title>
            <span class="headline"
              >{{ isWebEdit ? "Edit" : "Add" }} Website Slider</span
            >
          </v-card-title>
          <v-card-text>
            <v-form ref="form2" v-model="valid2" lazy-validation>
              <v-row dense>
                <v-col cols="12">
                  <v-file-input
                    v-model="webSlider.image"
                    filled
                    placeholder="Pick an Image"
                    prepend-icon="mdi-camera"
                    label="Add Image"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Title *"
                    required
                    :rules="[(v) => !!v || 'Title is required']"
                    v-model="webSlider.title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Title in Hindi *"
                    required
                    :rules="[(v) => !!v || 'Hindi Title is required']"
                    v-model="webSlider.h_title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-radio-group row v-model="webSlider.enable">
                    <v-radio label="Not Enable" :value="0"></v-radio>
                    <v-radio label="Enable" :value="1"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-form>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeButton()"> Close </v-btn>
            <v-btn color="secondary" text @click="submitWebSliderData()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!--App Delete confirmation -->
    <v-row justify="center">
      <v-dialog
        v-model="appDeleteConfirmDialog"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card>
          <v-toolbar color="warning" dark>Confirmation</v-toolbar>
          <v-card-text>
            <div class="headline pt-12">Do you want to remove this slider</div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="appDeleteConfirmDialog = false">No</v-btn>
            <v-btn text @click="confirmAppDelete()">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Website Delete confirmation -->
    <v-row justify="center">
      <v-dialog
        v-model="webDeleteConfirmDialog"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card>
          <v-toolbar color="warning" dark>Confirmation</v-toolbar>
          <v-card-text>
            <div class="headline pt-12">Do you want to remove this slider</div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="webDeleteConfirmDialog = false">No</v-btn>
            <v-btn text @click="confirmWebDelete()">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import { imageURL } from "@/assets/js/constant";
export default {
  data: () => ({
    appSliderId: "",
    webSliderId: "",
    appDeleteConfirmDialog: false,
    webDeleteConfirmDialog: false,
    kUrl: imageURL,
    addMobileSliderDialog: false,
    addWebSliderDialog: false,
    valid: true,
    valid2: true,
    isAppEdit: false,
    isWebEdit: false,
    mobileSlider: {
      title: "",
      h_title: "",
      image: null,
      enable: 0,
    },
    webSlider: {
      title: "",
      h_title: "",
      image: null,
      enable: 0,
    },
    mobileOldimage: "",
    webOldimage: "",
  }),
  props: {
    catId: {
      type: String,
    },
  },
  methods: {
    ...mapActions([
      "fetchMobileSliders",
      "fetchWebSliders",
      "addMobileSlider",
      "addWebSlider",
      "updateMobileSlider",
      "updateWebSlider",
      "deleteAppSlider",
      "deleteWebSlider",
    ]),
    async submitMobileSliderData() {
      if (this.$refs.form1.validate()) {
        this.addMobileSliderDialog = false;
        this.$vloading.show();
        try {
          if (this.isAppEdit) {
            this.mobileSlider.oldimage = this.mobileOldimage;
            this.mobileSlider.id = this.appSliderId;
            await this.updateMobileSlider(this.mobileSlider);
          } else {
            await this.addMobileSlider(this.mobileSlider);
          }
          this.$toasted.show("Successfully Done", {
            type: "success",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        } catch (error) {
          this.$toasted.show(error, {
            type: "error",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        }

        this.$vloading.hide();
      }
    },
    async submitWebSliderData() {
      if (this.$refs.form2.validate()) {
        this.addWebSliderDialog = false;
        this.$vloading.show();
        try {
          if (this.isWebEdit) {
            this.webSlider.oldimage = this.webOldimage;
            this.webSlider.id = this.webSliderId;
            await this.updateWebSlider(this.webSlider);
          } else {
            await this.addWebSlider(this.webSlider);
          }
          this.$toasted.show("Successfully Done", {
            type: "success",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        } catch (error) {
          this.$toasted.show(error, {
            type: "error",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        }

        this.$vloading.hide();
      }
    },
    editMobileButton(key) {
      this.addMobileSliderDialog = true;
      this.isAppEdit = true;
      this.mobileSlider.title = this.getAppSliders[key].title;
      this.mobileSlider.h_title = this.getAppSliders[key].h_title;
      this.mobileOldimage = this.getAppSliders[key].image;
      this.appSliderId = this.getAppSliders[key].id;
      this.mobileSlider.enable = parseInt(this.getAppSliders[key].is_enable);
    },
    editWebButton(key) {
      this.addWebSliderDialog = true;
      this.isWebEdit = true;
      this.webSlider.title = this.getWebSliders[key].title;
      this.webSlider.h_title = this.getWebSliders[key].h_title;
      this.webOldimage = this.getWebSliders[key].image;
      this.webSliderId = this.getWebSliders[key].id;
      this.webSlider.enable = parseInt(this.getWebSliders[key].is_enable);
    },
    closeButton() {
      this.isAppEdit = false;
      this.isWebEdit = false;
      this.addMobileSliderDialog = false;
      this.mobileSlider.title = "";
      this.mobileSlider.h_title = "";
      this.mobileSlider.image = null;
      this.mobileSlider.is_enable = 0;
      this.addWebSliderDialog = false;
      this.webSlider.title = "";
      this.webSlider.h_title = "";
      this.webSlider.image = null;
      this.webSlider.is_enable = 0;
    },

    appDeleteButton(id) {
      this.appDeleteConfirmDialog = true;
      this.appSliderId = id;
    },
    webDeleteButton(id) {
      this.webDeleteConfirmDialog = true;
      this.webSliderId = id;
    },

    async confirmAppDelete() {
      this.appDeleteConfirmDialog = false;
      this.$vloading.show();
      try {
        await this.deleteAppSlider(this.appSliderId);
        this.$toasted.show("Successfully removed", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
      this.$vloading.hide();
    },
    async confirmWebDelete() {
      this.webDeleteConfirmDialog = false;
      this.$vloading.show();
      try {
        await this.deleteWebSlider(this.webSliderId);
        this.$toasted.show("Successfully removed", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
      this.$vloading.hide();
    },
  },
  computed: {
    ...mapGetters(["getAppSliders", "getWebSliders"]),
  },
  async created() {
    this.$vloading.show();
    try {
      await this.fetchMobileSliders();
      await this.fetchWebSliders();
    } catch (error) {
      this.$toasted.show(error, {
        type: "error",
        duration: 5000,
        position: "top-center",
        theme: "bubble",
        icon: "mdi-clipboard-check",
        iconPack: "mdi",
      });
    }
    this.$vloading.hide();
  },
};
</script>

