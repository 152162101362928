<template>
  <v-container fluid>
    <section class="mb-12 text-center">
      <h1 class="mb-2 text-h3">Product Details</h1>
      <div class="body-2 font-weight-light">
        Click on + button to add a Product detail
      </div>
    </section>
    <v-card v-bind="$attrs" class="v-card--material mt-4 px-4" color="white">
      <v-card-title class="align-start">
        <v-btn
          @click="productDetailDialogButton()"
          :color="!CatProduct ? 'primary' : 'yellow'"
          class="overflow-hidden mt-n9 transition-swing v-card--material__sheet"
          elevation="3"
          fab
          x-large
          tile
          ><v-icon large>{{
            !CatProduct ? "mdi-plus" : "mdi-pencil"
          }}</v-icon></v-btn
        >
      </v-card-title>
      <p class="text-center" v-if="!CatProduct">Product Detail is empty</p>
      <!-- Product detail  -->
      <v-container v-if="CatProduct && ExtraCatProductImages" class="pb-8">
        <v-subheader class="pl-0">Images</v-subheader>
        <v-row>
          <v-col class="d-flex child-flex" cols="4">
            <v-img
              height="200px"
              :src="`${kUrl}/${CatProduct.image}`"
              :lazy-src="`${kUrl}/${CatProduct.image}`"
              aspect-ratio="1"
              class="grey lighten-2"
              contain
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-col
            v-for="extraImage in ExtraCatProductImages"
            :key="extraImage.id"
            class="d-flex child-flex"
            cols="4"
          >
            <v-hover v-slot="{ hover }">
              <v-img
                contain
                height="200px"
                :src="`${kUrl}/${extraImage.image}`"
                :lazy-src="`${kUrl}/${extraImage.image}`"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <v-expand-transition>
                  <v-btn
                    class="ma-2"
                    outlined
                    small
                    fab
                    color="red"
                    v-if="hover"
                    @click="handleExtraImageDelete(extraImage.id)"
                    :loading="extraImageDeleteLoader"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-expand-transition>
              </v-img>
            </v-hover>
          </v-col>
          <v-col class="d-flex child-flex" cols="4">
            <v-card height="200px">
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-col cols="12">
                  <v-file-input
                    filled
                    v-model="extraImage"
                    accept="image/png, image/jpeg, image/gif, image/jpg"
                    placeholder="Pick an Image"
                    prepend-icon="mdi-camera"
                    label="Add Image"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    block
                    :loading="extraImageLoader"
                    :disabled="!extraImage"
                    @click="handleAddExtraImage()"
                    >Add Image</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-subheader class="pl-0">Description</v-subheader>
        <p class="mt-2 headline">{{ CatProduct.description }}</p>
        <v-subheader class="pl-0">Description in Hindi</v-subheader>
        <p class="mt-2 headline">{{ CatProduct.h_description }}</p>

        <v-subheader class="pl-0">Details</v-subheader>
        <v-row dense>
          <v-col cols="12" sm="4"
            ><p class="headline">
              <b>Title -</b> {{ CatProduct.title }}/ {{ CatProduct.h_title }}
            </p>
          </v-col>
          <v-col cols="12" sm="4"
            ><p class="headline">
              <b>Sub Title -</b> {{ CatProduct.sub_title }}/
              {{ CatProduct.h_sub_title }}
            </p>
          </v-col>
          <v-col cols="12" sm="4"
            ><p class="headline"><b>Price - </b>₹ {{ CatProduct.price }}</p>
          </v-col>
          <v-col cols="12" sm="4"
            ><p class="headline">
              <b>Quantity - </b> {{ CatProduct.quantity }}
            </p>
          </v-col>
          <v-col cols="12" sm="4"
            ><p class="headline"><b>Enable - </b> {{ CatProduct.is_enable }}</p>
          </v-col>
          <v-col cols="12" sm="4"
            ><p class="headline">
              <b>Dimension - </b> {{ CatProduct.dimension }}
            </p>
          </v-col>
          <v-col cols="12" sm="4"
            ><p class="headline"><b>Weight - </b> {{ CatProduct.weight }}</p>
          </v-col>
          <v-col cols="12" sm="4"
            ><p class="headline">
              <b>Model Name - </b> {{ CatProduct.model_name }}
            </p>
          </v-col>
          <v-col cols="12" sm="4"
            ><p class="headline"><b>Type - </b> {{ CatProduct.type }}</p>
          </v-col>
          <v-col cols="12" sm="4"
            ><p class="headline">
              <b>Material - </b> {{ CatProduct.material }}
            </p>
          </v-col>
          <v-col cols="12" sm="4"
            ><p class="headline"><b>Brand - </b> {{ CatProduct.brand }}</p>
          </v-col>
          <!-- <v-col cols="12" sm="4"
            ><p class="headline"><b>Others - </b> {{ CatProduct.others }}</p>
          </v-col> -->
        </v-row>

        <v-subheader class="pl-0">Others</v-subheader>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Value</th>
                <th class="text-right">Remove</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="otherData in getOthersData" :key="otherData.id">
                <td class="text-left">{{ otherData.name }}</td>
                <td class="text-left">{{ otherData.value }}</td>
                <td class="text-right">
                  <v-icon
                    color="red darken-2"
                    @click="handleDeleteOther(otherData.id)"
                    :loading="isDeleteOtherLoader"
                  >
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row class="mt-4">
          <v-text-field
            v-model="others.name"
            label="Name"
            outlined
            dense
            class="ml-2"
            hide-details=""
          ></v-text-field>
          <v-text-field
            v-model="others.value"
            label="Value"
            outlined
            dense
            class="ml-2"
            hide-details=""
          ></v-text-field>
          <v-btn
            class="mx-2"
            color="primary"
            :disabled="!others.name || !others.value"
            :loading="isAddExtraOtherData"
            @click="handleAddOthersData()"
            >Add</v-btn
          >
        </v-row>
      </v-container>
      <!--  Dialog box  -->
      <v-row justify="center">
        <v-dialog v-model="addProductDetailDialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline"
                >{{ isEdit ? "Edit" : "Add" }} Product Detail</span
              >
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation class="mb-4">
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Dimension *"
                      required
                      :rules="[(v) => !!v || 'Dimension is required']"
                      v-model="product.dimension"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Weight *"
                      required
                      :rules="[(v) => !!v || 'Weight is required']"
                      v-model="product.weight"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Model Name *"
                      required
                      :rules="[(v) => !!v || 'Model Name is required']"
                      v-model="product.model_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Type *"
                      required
                      :rules="[(v) => !!v || 'Type is required']"
                      v-model="product.type"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Material *"
                      required
                      :rules="[(v) => !!v || 'Material is required']"
                      v-model="product.material"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Brand *"
                      required
                      :rules="[(v) => !!v || 'Brand is required']"
                      v-model="product.brand"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Description *"
                      required
                      :rules="[(v) => !!v || 'Description is required']"
                      v-model="product.description"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Description in Hindi *"
                      required
                      :rules="[
                        (v) => !!v || 'Description in Hindi is required',
                      ]"
                      v-model="product.h_description"
                    >
                    </v-textarea>
                  </v-col>
                  <!-- <v-col cols="12">
                    <v-text-field
                      label="Others *"
                      required
                      :rules="[(v) => !!v || 'Others is required']"
                      v-model="product.others"
                    ></v-text-field>
                  </v-col> -->

                  <!-- Others  -->
                  <!-- {{ others }}
                  <v-subheader class="mb-3 pl-0"> Others </v-subheader>
                  <v-spacer></v-spacer>
                  <v-btn @click="addNewCollaborator()"> add</v-btn>
                  <v-row
                    v-for="(other, key) in others"
                    :key="`Other${key}`"
                    class=""
                  >
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model="other.field"
                        dense
                        label="Name"
                        outlined
                        flat
                        hide-details
                        class=""
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="9">
                      <v-text-field
                        v-model="other.value"
                        dense
                        label="Value"
                        outlined
                        flat
                        hide-details
                        class=""
                      ></v-text-field>
                    </v-col>
                  </v-row> -->
                </v-row>
              </v-form>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="addProductDetailDialog = false">
                Close
              </v-btn>
              <v-btn color="secondary" text @click="submitProductDetailData()">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { imageURL } from "@/assets/js/constant";
export default {
  data: () => ({
    kUrl: imageURL,
    extraImage: null,
    extraImageLoader: false,
    addProductDetailDialog: false,
    isDeleteOtherLoader: false,
    isEdit: false,
    valid: true,
    product: {
      description: "",
      h_description: "",
      dimension: "",
      weight: "",
      model_name: "",
      type: "",
      material: "",
      brand: "",
    },
    isAddExtraOtherData: false,
    others: {
      name: "",
      value: "",
    },
    extraImageDeleteLoader: false,
  }),
  props: {
    productId: {
      type: String,
    },
  },
  methods: {
    ...mapActions([
      "fetchProductDetailByProdId",
      "addProductDetail",
      "updateProductDetail",
      "fetchProductImagesById",
      "addExtraImage",
      "deleteExtraImage",
      "fetchOtherDetails",
      "addOtherData",
      "deleteOtherData",
    ]),
    productDetailDialogButton() {
      this.addProductDetailDialog = !this.addProductDetailDialog;
      if (this.CatProduct) {
        const {
          description,
          h_description,
          dimension,
          weight,
          model_name,
          type,
          material,
          brand,
          others,
          productId,
          productDetailId,
        } = this.CatProduct;
        this.product.description = description;
        this.product.h_description = h_description;
        this.product.dimension = dimension;
        this.product.weight = weight;
        this.product.model_name = model_name;
        this.product.type = type;
        this.product.material = material;
        this.product.brand = brand;
        this.others = others;
        this.product.productId = productId;
        this.product.productDetailId = productDetailId;
      }
    },

    async submitProductDetailData() {
      if (this.$refs.form.validate()) {
        this.addProductDetailDialog = false;
        this.$vloading.show();
        try {
          if (this.CatProduct) {
            await this.updateProductDetail(this.product);
          } else {
            this.product.productId = this.productId;
            // this.product.others = this.others;
            console.log(this.product);
            await this.addProductDetail(this.product);
          }

          this.$toasted.show("Successfully Done", {
            type: "success",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        } catch (error) {
          this.$toasted.show(error, {
            type: "error",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        }

        this.$vloading.hide();
      }
    },
    async handleAddExtraImage() {
      this.extraImageLoader = true;
      try {
        await this.addExtraImage({
          product_id: this.productId,
          image: this.extraImage,
        });
        this.extraImage = null;
        this.$toasted.show("Successfully Done", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }

      this.extraImageLoader = false;
    },

    // addNewCollaborator() {
    //   this.others.push({
    //     field: "Field",
    //     value: "Value",
    //   });
    // },
    async handleExtraImageDelete(id) {
      this.extraImageDeleteLoader = true;
      try {
        await this.deleteExtraImage(id);
        this.$toasted.show("Successfully removed", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
      this.extraImageDeleteLoader = false;
      // this.$vloading.hide();
    },
    async handleDeleteOther(id) {
      console.log(id)
      this.isDeleteOtherLoader = true;
      try {
        await this.deleteOtherData(id);
        this.$toasted.show("Successfully removed", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
      this.isDeleteOtherLoader = false;
      // this.$vloading.hide();
    },
    async handleAddOthersData() {
      this.isAddExtraOtherData = true;
      try {
        this.others.productId = this.productId;
        await this.addOtherData(this.others);
        this.$toasted.show("Successfully added", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
      this.isAddExtraOtherData = false;
      this.others.name = "";
      this.others.value = "";
    },
  },
  computed: {
    ...mapGetters(["CatProduct", "ExtraCatProductImages", "getOthersData"]),
  },
  async created() {
    this.$vloading.show();
    try {
      await this.fetchProductDetailByProdId(this.productId);
      await this.fetchProductImagesById(this.productId);
      await this.fetchOtherDetails(this.productId);
    } catch (error) {
      this.$toasted.show(error, {
        type: "error",
        duration: 5000,
        position: "top-center",
        theme: "bubble",
        icon: "mdi-clipboard-check",
        iconPack: "mdi",
      });
    }
    this.$vloading.hide();
  },
};
</script>

