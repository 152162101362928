<template>
  <v-container fluid>
    <section class="mb-12 text-center">
      <h1 class="mb-2 text-h3">Testimonials</h1>

      <div class="body-2 font-weight-light">
        Click on + button to add a new testimonial
      </div>
    </section>
    <v-card v-bind="$attrs" class="v-card--material mt-4 px-4" color="white">
      <v-card-title class="align-start">
        <v-btn
          @click="addTestimonialDialog = !addTestimonialDialog"
          color="primary"
          class="overflow-hidden mt-n9 transition-swing v-card--material__sheet"
          elevation="3"
          fab
          x-large
          tile
          ><v-icon large>mdi-plus</v-icon></v-btn
        >
      </v-card-title>
      <div v-if="!GetTestimonials.length" class="text-center py-4">
        No Testimonials
      </div>

      <!-- List of testimonials -->
      <v-row v-else dense class="py-4">
        <v-col
          v-for="(testimonial, key) in GetTestimonials"
          :key="testimonial.id"
          cols="12"
          sm="6"
          md="3"
        >
          <v-card>
            <v-img
              contain
              :src="`${kUrl}/${testimonial.image}`"
              :lazy-src="`${kUrl}/${testimonial.image}`"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
              <v-card-title class="headline"
                >{{ testimonial.company }} /
                {{ testimonial.h_company }}</v-card-title
              >
            </v-img>
            <v-card-text>
              {{ testimonial.description }} / {{ testimonial.h_description }}
            </v-card-text>
            <v-card-actions>
              <v-btn color="secondary" text @click="editButton(key)">
                Edit
              </v-btn>
              <v-divider></v-divider>

              <!-- <v-btn color="error" text @click="deleteButton(category.id)"> -->
              <v-btn color="error" text >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <!--  Dialog box  -->
    <v-row justify="center">
      <v-dialog v-model="addTestimonialDialog" persistent max-width="600px">
        <v-card
          ><v-img
            contain
            v-if="isEdit"
            :src="`${kUrl}/${oldimage}`"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          />
          <v-card-title>
            <span class="headline"
              >{{ isEdit ? "Edit" : "Add" }} Testimonial</span
            >
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row dense>
                <v-col cols="12">
                  <v-file-input
                    filled
                    v-model="testimonial.image"
                    accept="image/png, image/jpeg, image/gif"
                    placeholder="Pick an Image"
                    prepend-icon="mdi-camera"
                    label="Add Image"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Company *"
                    required
                    :rules="[(v) => !!v || 'Company is required']"
                    v-model="testimonial.company"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Company in Hindi *"
                    required
                    :rules="[(v) => !!v || 'Hindi Company is required']"
                    v-model="testimonial.h_company"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Description *"
                    required
                    :rules="[(v) => !!v || 'Description is required']"
                    v-model="testimonial.description"
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Description in Hindi *"
                    required
                    :rules="[(v) => !!v || 'Description in Hindi is required']"
                    v-model="testimonial.h_description"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-form>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeButton()"> Close </v-btn>
            <v-btn color="secondary" text @click="submitTestimonialData()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Delete confirmation -->
    <v-row justify="center">
      <v-dialog
        v-model="deleteConfirmDialog"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card>
          <v-toolbar color="warning" dark>Confirmation</v-toolbar>
          <v-card-text>
            <div class="headline pt-12">
              Do you want to remove this Categories
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="deleteConfirmDialog = false">No</v-btn>
            <v-btn text @click="confirmDelete()">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { imageURL } from "@/assets/js/constant";

export default {
  data: () => ({
    testimonialId: "",
    kUrl: imageURL,
    valid: true,
    isEdit: false,
    deleteConfirmDialog: false,
    oldimage: "",
    addTestimonialDialog: false,
    testimonial: {
      company: "",
      h_company: "",
      image: null,
      description: "",
      h_description: "",
    },
    is_enable: 0,
  }),
  methods: {
    ...mapActions([
      "fetchAllTestimonials",
      "addTestimonial",
      "deleteCategory",
      "updateTestimonial",
    ]),

    async submitTestimonialData() {
      if (this.$refs.form.validate()) {
        this.addTestimonialDialog = false;
        this.$vloading.show();
        try {
          if (this.isEdit) {
            this.testimonial.oldimage = this.oldimage;
            this.testimonial.id = this.testimonialId;
            await this.updateTestimonial(this.testimonial);
          } else {
            await this.addTestimonial(this.testimonial);
          }
          this.$toasted.show("Successfully Done", {
            type: "success",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        } catch (error) {
          this.$toasted.show(error, {
            type: "error",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        }

        this.$vloading.hide();
      }
    },
    deleteButton(id) {
      this.deleteConfirmDialog = true;
      this.testimonialId = id;
    },
    async confirmDelete() {
      this.deleteConfirmDialog = false;
      this.$vloading.show();
      try {
        await this.deleteCategory(this.testimonialId);
        this.$toasted.show("Successfully removed", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
      this.$vloading.hide();
    },
    editButton(key) {
      this.isEdit = true;
      this.addTestimonialDialog = true;
      this.testimonial.company = this.GetTestimonials[key].company;
      this.testimonial.h_company = this.GetTestimonials[key].h_company;
      this.testimonial.description = this.GetTestimonials[key].description;
      this.testimonial.h_description = this.GetTestimonials[key].h_description;
      this.oldimage = this.GetTestimonials[key].image;
      this.testimonialId = this.GetTestimonials[key].id;
    },

    closeButton() {
      this.isEdit = false;
      this.addTestimonialDialog = false;
      this.category.name = "";
      this.category.h_name = "";
      this.category.catimage = null;
      this.category.is_popular = 0;
    },
  },
  computed: {
    ...mapGetters(["GetTestimonials"]),
  },
  async created() {
    this.$vloading.show();
    try {
      await this.fetchAllTestimonials();
    } catch (error) {
      this.$toasted.show(error, {
        type: "error",
        duration: 5000,
        position: "top-center",
        theme: "bubble",
        icon: "mdi-clipboard-check",
        iconPack: "mdi",
      });
    }
    this.$vloading.hide();
  },
};
</script>

