<template>
  <v-container fluid>
    <section class="mb-12 text-center">
      <h1 class="mb-2 text-h3">Popular Categories</h1>

      <div class="body-2 font-weight-light">
        Click on edit button to edit popular category
      </div>
    </section>
    <v-card v-bind="$attrs" class="v-card--material mt-4 px-4" color="white">
      <!-- List of categories -->
      <div v-if="!GetpopularCategories.length" class="text-center py-4">
        No Popular Products
      </div>

      <v-row v-else dense class="py-4">
        <v-col
          v-for="(category, key) in GetpopularCategories"
          :key="category.id"
          cols="12"
          sm="6"
          md="3"
        >
          <v-card>
            <v-img
              contain
              :src="`${kUrl}/${category.image}`"
              :lazy-src="`${kUrl}/${category.image}`"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
              @click="
                $router.push({
                  name: 'Products',
                  params: { catId: category.id },
                })
              "
              style="cursor: pointer"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
              <v-card-title class="headline"
                >{{ category.name }}/ {{ category.h_name }}</v-card-title
              >
            </v-img>
            <!-- <v-list-item-content > -->
            <div class="text-center">
              <v-chip
                pill
                x-small
                v-if="category.is_popular == '1'"
                class="py-0"
                color="orange"
                text-color="white"
              >
                Popular
                <v-icon x-small right> mdi-star </v-icon>
              </v-chip>
              <v-chip
                pill
                x-small
                v-if="category.is_enable == '1'"
                class="py-0 ml-1"
                color="primary"
                text-color="white"
              >
                Enable
                <v-icon x-small right> mdi-star </v-icon>
              </v-chip>
            </div>
            <v-card-actions>
              <v-btn color="secondary" text @click="editButton(key)">
                Edit
              </v-btn>
              <v-divider></v-divider>

              <v-btn color="error" text @click="deleteButton(category.id)">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <!--  Dialog box  -->
    <v-row justify="center">
      <v-dialog
        v-model="editPopularCategoryDialog"
        persistent
        max-width="600px"
      >
        <v-card
          ><v-img
            contain
            :src="`${kUrl}/${oldimage}`"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          />
          <v-card-title>
            <span class="headline">Edit Category</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row dense>
                <v-col cols="12">
                  <v-file-input
                    filled
                    v-model="category.catimage"
                    accept="image/png, image/jpeg, image/gif"
                    placeholder="Pick an Image"
                    prepend-icon="mdi-camera"
                    label="Add Image"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Title *"
                    required
                    :rules="[(v) => !!v || 'Title is required']"
                    v-model="category.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Title in Hindi *"
                    required
                    :rules="[(v) => !!v || 'Hindi Title is required']"
                    v-model="category.h_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-radio-group row v-model="category.is_popular">
                    <v-radio label="Not Popular" :value="0"></v-radio>
                    <v-radio label="Popular" :value="1"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-radio-group row v-model="category.is_enable">
                    <v-radio label="Not Enable" :value="0"></v-radio>
                    <v-radio label="Enable" :value="1"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-form>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="editPopularCategoryDialog = false"
            >
              Close
            </v-btn>
            <v-btn color="secondary" text @click="submitCategoryData()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Delete confirmation -->
    <v-row justify="center">
      <v-dialog
        v-model="deleteConfirmDialog"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card>
          <v-toolbar color="warning" dark>Confirmation</v-toolbar>
          <v-card-text>
            <div class="headline pt-12">
              Do you want to remove this Categories
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="deleteConfirmDialog = false">No</v-btn>
            <v-btn text @click="confirmDelete()">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { imageURL } from "@/assets/js/constant";

export default {
  data: () => ({
    categoryId: "",
    kUrl: imageURL,
    valid: true,
    deleteConfirmDialog: false,
    editPopularCategoryDialog: false,
    category: {
      name: "",
      h_name: "",
      catimage: null,
      is_popular: 0,
      is_enable: 0,
    },
    oldimage: "",
  }),
  methods: {
    ...mapActions([
      "fetchAllpopularCategories",
      "deletePopularCategory",
      "updatePopularCategory",
    ]),
    editButton(key) {
      this.editPopularCategoryDialog = true;
      this.categoryId = this.GetpopularCategories[key].id;
      this.category.name = this.GetpopularCategories[key].name;
      this.category.h_name = this.GetpopularCategories[key].h_name;
      this.oldimage = this.GetpopularCategories[key].image;
      this.category.is_popular = parseInt(
        this.GetpopularCategories[key].is_popular
      );
      this.category.is_enable = parseInt(
        this.GetpopularCategories[key].is_enable
      );
    },
    async submitCategoryData() {
      if (this.$refs.form.validate()) {
        this.editPopularCategoryDialog = false;
        this.$vloading.show();
        try {
          this.category.oldimage = this.oldimage;
          this.category.cat_id = this.categoryId;
          await this.updatePopularCategory(this.category);
          this.$toasted.show("Successfully Done", {
            type: "success",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        } catch (error) {
          this.$toasted.show(error, {
            type: "error",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        }

        this.$vloading.hide();
      }
    },
    deleteButton(id) {
      this.deleteConfirmDialog = true;
      this.categoryId = id;
    },
    async confirmDelete() {
      this.deleteConfirmDialog = false;
      this.$vloading.show();
      try {
        await this.deletePopularCategory(this.categoryId);
        this.$toasted.show("Successfully removed", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
      this.$vloading.hide();
    },
  },
  computed: {
    ...mapGetters(["GetpopularCategories"]),
  },
  async created() {
    this.$vloading.show();
    try {
      await this.fetchAllpopularCategories();
    } catch (error) {
      this.$toasted.show(error, {
        type: "error",
        duration: 5000,
        position: "top-center",
        theme: "bubble",
        icon: "mdi-clipboard-check",
        iconPack: "mdi",
      });
    }
    this.$vloading.hide();
  },
};
</script>

