<template>
  <v-container fluid>
    <section class="mb-12 text-center">
      <h1 class="mb-2 text-h3">Carts</h1>

      <div class="body-2 font-weight-light">No of products in cart</div>
    </section>
    <!-- Main card  -->
    <v-card v-bind="$attrs" class="v-card--material mt-4 px-4" color="white">
      <div v-if="!GetCarts.length" class="text-center py-4">Empty Carts</div>
      <v-row v-else dense class="py-4">
        <!-- List of slider  -->
        <v-col v-for="cart in GetCarts" :key="cart.id" cols="12" sm="4">
          <v-card>
            <v-img
              contain
              :src="`${kUrl}/${cart.image}`"
              :lazy-src="`${kUrl}/${cart.image}`"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <!-- Image loading  -->
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
              <v-card-title class="headline"
                >{{ cart.title }}/ {{ cart.h_title }}</v-card-title
              >
            </v-img>

            <div class="text-center">
              <v-chip
                pill
                small
                class="mt-1"
                color="primary"
                text-color="white"
              >
                ₹ {{ cart.price }}
              </v-chip>
            </div>
            <v-list dense>
              <v-list-item class="mb-0">
                <v-list-item-icon>
                  <v-icon>mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ cart.email }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="mb-0">
                <v-list-item-icon>
                  <v-icon>mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ cart.mobile }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { imageURL } from "@/assets/js/constant";

export default {
  data: () => ({
    categoryId: "",
    kUrl: imageURL,
    valid: true,
    deleteConfirmDialog: false,
    editPopularCategoryDialog: false,
  }),
  methods: {
    ...mapActions(["fetchAllCarts"]),
  },
  computed: {
    ...mapGetters(["GetCarts"]),
  },
  async created() {
    this.$vloading.show();
    try {
      await this.fetchAllCarts();
    } catch (error) {
      this.$toasted.show(error, {
        type: "error",
        duration: 5000,
        position: "top-center",
        theme: "bubble",
        icon: "mdi-clipboard-check",
        iconPack: "mdi",
      });
    }
    this.$vloading.hide();
  },
};
</script>