<template>
  <v-container fluid>
    <section class="mb-12 text-center">
      <h1 class="mb-2 text-h3">Reviews</h1>
    </section>
    <v-card v-bind="$attrs" class="v-card--material mt-4 px-4" color="white">
      <div v-if="!GetReviews.length" class="text-center py-4">No Reviews</div>

      <!-- List of testimonials -->
      <v-row v-else dense class="py-4">
        <v-col
          v-for="(review, key) in GetReviews"
          :key="review.id"
          cols="12"
          sm="6"
          md="3"
        >
          <v-card>
            <v-card-title class="text-h5">
              User Id: {{ review.user_id }}</v-card-title
            >
            <v-card-text>
              {{ review.description }}

              <div class="text-center mt-12">
                <v-rating
                  color="yellow darken-3"
                  background-color="grey darken-1"
                  empty-icon="$ratingFull"
                  readonly
                  small
                  :value="parseInt(review.rating)"
                ></v-rating>
              </div>
            </v-card-text>
            <div class="text-center">
              <v-chip
                pill
                x-small
                v-if="review.is_enable == '1'"
                class="py-0 ml-1"
                color="primary"
                text-color="white"
              >
                Enable
                <v-icon x-small right> mdi-star </v-icon>
              </v-chip>
            </div>
            <v-card-actions>
              <v-btn color="secondary" text @click="editButton(key)">
                Edit
              </v-btn>
              <!-- <v-divider></v-divider>

              <v-btn color="error" text @click="deleteButton(category.id)">
                Delete
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <!--  Dialog box  -->
    <v-row justify="center">
      <v-dialog v-model="editDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Review</span>
          </v-card-title>

          <v-card-text>
            {{ description }}

            <div class="mt-5">
              <v-rating
                color="yellow darken-3"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                readonly
                large
                :value="rating"
              ></v-rating>
            </div>
            <v-col cols="12">
              <v-radio-group row v-model="is_enable">
                <v-radio label="Not Enable" :value="0"></v-radio>
                <v-radio label="Enable" :value="1"></v-radio>
              </v-radio-group>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="editDialog = false">
              Close
            </v-btn>
            <v-btn color="secondary" text @click="submitReviewData()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { imageURL } from "@/assets/js/constant";

export default {
  data: () => ({
    testimonialId: "",
    kUrl: imageURL,
    valid: true,
    isEdit: false,
    is_enable: 0,
    editDialog: false,
    description: "",
    rating: 0,
    reviewId: "",
  }),
  methods: {
    ...mapActions(["fetchAllReviews", "updateReview"]),

    async submitReviewData() {
      this.editDialog = false;
      this.$vloading.show();
      try {
        await this.updateReview({
          reviewId: this.reviewId,
          isEnable: this.is_enable,
        });

        this.$toasted.show("Successfully Done", {
          type: "success",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }

      this.$vloading.hide();
    },

    editButton(key) {
      this.editDialog = true;
      this.is_enable = parseInt(this.GetReviews[key].is_enable);
      this.description = this.GetReviews[key].description;
      this.rating = parseInt(this.GetReviews[key].rating);
      this.reviewId = this.GetReviews[key].id;
    },
  },
  computed: {
    ...mapGetters(["GetReviews"]),
  },
  async created() {
    this.$vloading.show();
    try {
      await this.fetchAllReviews();
    } catch (error) {
      this.$toasted.show(error, {
        type: "error",
        duration: 5000,
        position: "top-center",
        theme: "bubble",
        icon: "mdi-clipboard-check",
        iconPack: "mdi",
      });
    }
    this.$vloading.hide();
  },
};
</script>

