<template>
  <v-container fluid>
    <section class="mb-12 text-center">
      <h1 class="mb-2 text-h3">Orders</h1>
      <div class="body-2 font-weight-light">Detail of Orders</div>
    </section>
    <!-- Main card  -->
    <v-card v-bind="$attrs" class="v-card--material mt-4 px-4" color="white">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <!-- Table of orders  -->
      <v-data-table :headers="headers" :items="GetOrders" :search="search">
        <template v-slot:[`item.code`]="{ item }">
          <v-icon
            :color="item.code == 200 ? 'green lighten-1' : 'red lighten-1'"
            dark
            >{{
              item.code == 200
                ? "mdi-check-circle-outline"
                : "mdi-close-circle-outline"
            }}</v-icon
          >
        </template>

        <template v-slot:[`item.amount`]="{ item }">
          ₹ {{ item.amount }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" @click="viewOrder(item.id)"> mdi-eye </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <!-- Dialog box  -->
    <v-dialog transition="dialog-bottom-transition" v-model="ordersDialog">
      <v-card>
        <!-- Table of order  -->
        <v-data-table :headers="headers2" :items="GetOrder" :search="search2">
          <template v-slot:[`item.image`]="{ item }"
            ><v-avatar tile>
              <img :src="`${kUrl}/${item.image}`" />
            </v-avatar>
          </template>
        </v-data-table>
        <v-card-actions class="justify-end">
          <v-btn text @click="ordersDialog = false" color="error">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { imageURL } from "@/assets/js/constant";

export default {
  data: () => ({
    kUrl: imageURL,
    ordersDialog: false,
    search: "",
    search2: "",
    headers: [
      {
        text: "Order Id",
        align: "start",
        value: "id",
      },
      { text: "User Id", value: "user_id" },
      { text: "Amount", value: "amount" },
      { text: "Order Status", value: "order_status" },
      { text: "Payment Status", value: "payment_status" },
      { text: "Transaction id", value: "transaction_id" },
      { text: "Signature", value: "signature" },
      { text: "Message", value: "message" },
      { text: "Code", value: "code" },
      { text: "Order Date", value: "order_date" },
      { text: "View", value: "actions" },
    ],
    headers2: [
      {
        text: "Order Id",
        align: "start",
        value: "order_id",
      },
      { text: "Image", value: "image" },
      { text: "Title", value: "title" },
      { text: "H Title", value: "h_title" },
      { text: "Sub Title", value: "sub_title" },
      { text: "H Sub title", value: "h_sub_title" },
      { text: "Quantity", value: "quantity" },
      { text: "Price", value: "price" },
      { text: "Taz", value: "tax" },
      { text: "Insert Time", value: "insert_time" },
    ],
  }),
  methods: {
    ...mapActions(["fetchAllOrders", "fetchOrder"]),
    async viewOrder(id) {
      this.ordersDialog = true;
      try {
        await this.fetchOrder(id);
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
    },
  },
  computed: {
    ...mapGetters(["GetOrders", "GetOrder"]),
  },
  async created() {
    this.$vloading.show();
    try {
      await this.fetchAllOrders();
    } catch (error) {
      this.$toasted.show(error, {
        type: "error",
        duration: 5000,
        position: "top-center",
        theme: "bubble",
        icon: "mdi-clipboard-check",
        iconPack: "mdi",
      });
    }
    this.$vloading.hide();
  },
};
</script>